import styled from 'styled-components/macro'
import { useForm } from 'react-hook-form'
import { memo } from 'react'
import DefaultTerms from './BaseTerms'
import { CheckBoxContainer, ContentAgreementStyled, FormStyled, LabelStyled } from './styled'
import CustomCheckBox from '../../../../core/components/CustomCheckBox'
import CustomButton from '../../../../core/components/buttons/CustomButton'
import { useStepperContext } from '../../../../core/components/stepper/StepperContext'

const ContentStyled = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  height: 800px;
  overflow-y: auto;
  padding: 30px;
`

type TForm = {
  checkbox1: boolean
  checkbox2: boolean
}
const MakeMyHouseGreenTerms = () => {
  const { nextStep } = useStepperContext()
  const { control, watch } = useForm<TForm>({
    mode: 'onChange',
    defaultValues: {
      checkbox1: false,
      checkbox2: false
    }
  })
  const isButtonDisabled = !watch('checkbox1') || !watch('checkbox2')
  return (
    <>
      <ContentStyled>
        <DefaultTerms/>
      </ContentStyled>
      <ContentAgreementStyled>
        <FormStyled>
          <CheckBoxContainer>
            <LabelStyled>
              <CustomCheckBox
                control={control}
                name="checkbox1"/>
              I accept Plend Terms & Conditions
            </LabelStyled>
            <LabelStyled marginTop>
              <CustomCheckBox
                control={control}
                name="checkbox2"/>
              I accept Modulr’s Terms & Conditions
            </LabelStyled>
          </CheckBoxContainer>
          {/*
            <CustomButton text="Next" onClick={nextStep} disabled={isButtonDisabled}/>
          */}
        </FormStyled>
      </ContentAgreementStyled>
    </>
  )
}

export default memo(MakeMyHouseGreenTerms)
